<template>
    <b-modal id="confirm-transfer-request" ref="confirm-transfer-request" :title="title" ok-title="Approve"
        cancel-title="Reject" @cancel="rejectTransferRequest" @ok="approveTransferRequest"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" cancel-variant="danger"
        ok-variant="primary" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-container fluid>
            <b-row class="mb-2">
                <b-col sm="12">

                    User <b>{{ name }}</b> is requesting to transfer company from <b>{{ currCompany }}</b> to <b>{{
                        newCompany
                    }}</b>.

                </b-col>
            </b-row>

        </b-container>
    </b-modal>
</template>

<script>
// API
import requestApi from '@/api/requestApi';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'confirm-transfer-request',
    components: {
        Loading,
    },
    props: {
        allCompaniesObj: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selRequest: {},
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        title() {
            return 'Confirm Transfer Request ' + this.selRequest.requestId;
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        name() {
            return this.selRequest.firstName + ' ' + this.selRequest.lastName;
        },
        currCompany() {
            return this.selRequest.currCompany;
        },
        newCompany() {
            return this.selRequest.newCompany;
        }
    },
    mounted() {
        EventBus.$on('onUpdateSelTransferRequest', (item) => {
            if (this.$refs['confirm-transfer-request']) {
                this.$refs['confirm-transfer-request'].show();
            }
            this.selRequest = item;
        });
    },
    methods: {
        async rejectTransferRequest(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            this.selRequest.status = 'Rejected';
            this.selRequest.updatedBy = this.loggedUser.id;
            this.selRequest.dateUpdated = DateUtil.getCurrentTimestamp();

            await this.saveTransferRequest(false);
        },
        async approveTransferRequest(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            if (!this.selRequest.newCompany) {
                this.$toaster.warning("Invalid Request " + this.selRequest.requestId + "! No selected company to transfer. This request must be REJECTED instead.");
                return;
            }

            this.selRequest.status = 'Approved';
            this.selRequest.updatedBy = this.loggedUser.id;
            this.selRequest.dateUpdated = DateUtil.getCurrentTimestamp();

            await this.saveTransferRequest(true);
        },
        async saveTransferRequest(isApproved) {
            // show loading indicator
            this.isLoading = true;

            try {
                let requests = {};
                requests[this.selRequest.id] = this.selRequest;

                let { data } = await requestApi.confirmTransferCompanyRequests(requests, this.loggedUser.id);

                // hide loading indicator
                this.isLoading = false;

                if (data.isSuccess) {

                    if (isApproved) {
                        this.$toaster.success("Transfer Request " + this.selRequest.requestId + " was successfully APPROVED.");
                    } else {
                        this.$toaster.success("Transfer Request " + this.selRequest.requestId + " was successfully REJECTED.");
                    }

                    EventBus.$emit('onConfirmTransferRequest', data.transferRequests[this.selRequest.id]);
                    this.$refs['confirm-transfer-request'].hide();

                } else {
                    this.$toaster.error("Error encountered while confirming transfer request " + this.selRequest.requestId
                        + ". Please try again later.");
                }
            } catch (error) {
                this.$toaster.error("Error encountered while confirming transfer request " + this.selRequest.requestId
                    + ". Please try again later.");

                // hide loading indicator
                this.isLoading = false;
            }
        }
    },
};
</script>

<style scoped></style>