<template>
    <div>
        <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
            @click.stop="row.toggleDetails" class="mr-1 mt-1">
            <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
            <i class="fa fa-eye" v-else></i>
        </b-button>

        <b-button size="sm" v-b-tooltip.hover.top="'Confirm Transfer Request'" variant="primary"
            @click.stop="updateSelTransferRequest(row.item)" class="mr-1 mt-1"
            v-show="!isViewer && row.item.status === 'Open'">
            <b-icon icon="check-square"></b-icon>
        </b-button>
    </div>
</template>

<script>
import EventBus from '@/shared/event-bus';

export default {
    name: 'transfer-request-row-actions',
    props: {
        row: {
            type: Object,
            required: true,
        },
        isSuperAdmin: {
            type: Boolean,
            required: true,
        },
        isViewer: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            loggedUserCompany: this.$store.getters.loggedUserCompany,
        };
    },
    computed: {
        status() {
            return this.row && this.row.item.status;
        },
        currCompanyId() {
            return this.loggedUserCompany.id;
        },
    },
    methods: {
        updateSelTransferRequest(item) {
            this.selRequest = item;
            EventBus.$emit('onUpdateSelTransferRequest', item);
        },
    },
};
</script>

<style scoped></style>

