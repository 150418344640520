import { render, staticRenderFns } from "./ConfirmTransferRequests.vue?vue&type=template&id=95180304&scoped=true&"
import script from "./ConfirmTransferRequests.vue?vue&type=script&lang=js&"
export * from "./ConfirmTransferRequests.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmTransferRequests.vue?vue&type=style&index=0&id=95180304&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95180304",
  null
  
)

export default component.exports